.header {
  display: flex;
  background-color: var(--white);
  padding: 10px 10px 20px;
  margin: -19px -24px 24px -24px;
}

.container {
  padding: 15px 20px 20px;
  background-color: var(--white);

  .divider {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
}
