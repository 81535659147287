.modal {
  max-width: 800px;
  &-title {
    text-align: center;

    &-icon {
      font-size: 84px;
      color: #e2e3e3;
    }

    p {
      font-size: 13px;
      font-weight: normal;
      line-height: normal;
      font-family: inherit;
    }
  }
}
