// .buttons {
//   display: flex;
//   gap: 20px;
//   flex-flow: row wrap;
//   justify-content: center;
// }

.footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}
