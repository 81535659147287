.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 35px 2fr 2fr 1fr;
  border-bottom: 1px solid #929292;

  & > span {
    display: flex;
    align-items: center;
    padding: 10px;
  }

  .label {
    padding-right: 5px;
  }
}
