.body {
  display: grid;
  gap: 30px;
  background: #f8fafb;
  padding: 20px 30px 30px 30px;
}

.footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}
