.accordion {
  margin-top: 20px;
  background-color: #f8fafb;

  .offer {
    display: grid;
    gap: 30px;
    background: #f8fafb;
    padding: 20px 30px 30px 30px;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
