.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 2fr 2fr 2fr 80px 2fr;
  border-bottom: 1px solid #929292;

  & > span {
    display: flex;
    align-items: center;
    padding: 10px 7px;

    &:last-of-type {
      padding-right: 0;
    }
  }

  .label {
    padding-right: 5px;
  }
}

.offer {
  background-color: #e5e4e2;
}
