.row {
  margin-bottom: 10px;
}

.header {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  background-color: var(--white);
  padding: 10px 20px 20px;
  margin: -19px -24px 24px;
  gap: 20px;

  .item:last-of-type {
    text-align: right;

    button {
      background-color: var(--primary);
      border-color: var(--primary);

      &:hover {
        background-color: var(--primary);
        border-color: var(--primary);
      }
    }
  }
}
