.modal {
  max-width: 800px;
  &-title {
    text-align: center;

    &-icon {
      font-size: 84px;
      color: #e2e3e3;
    }
  }
}
