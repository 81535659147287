.form {
  background-color: var(--white);
  padding: 15px 20px 20px;
}

.header {
  background-color: var(--white);
  padding: 10px 10px 20px;
  margin: -19px -24px 24px -24px;
}
