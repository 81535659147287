@import './colors';

* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.2;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: var(--white);
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 15px !important;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }

  &-body {
    flex: 1 1 auto;
    padding: 1rem 1rem;
  }
}

.bold {
  font-weight: bold;
}

.dashes {
  border-top: 1px dashed #e7eaec;
  color: #ffffff;
  background-color: #ffffff;
  height: 1px;
  margin: 20px 0;
}

.divider {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.separator {
  padding: 0 0 0 5px;
}

.mb-05 {
  margin-bottom: 0.5rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.breadcrumbs {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    display: inline;
  }

  &-item {
    padding-right: 0.5rem;
    color: inherit;
  }

  &-item::after {
    display: inline-block;
    padding-left: 0.5rem;
    content: '/';
  }
}

.form-group {
  display: grid;
  grid-auto-flow: column;
  gap: 30px;
  margin-bottom: 1rem;

  &-one {
    width: 50%;
  }

  &-three {
    grid-auto-columns: 2fr 1fr 1fr;
  }

  &-four {
    grid-auto-columns: 1fr 1fr 1fr 1fr;
  }

  &-five {
    grid-auto-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  .input-group {
    margin-bottom: 1rem;
  }
}

// Overwriting Legato styles:
.react-datepicker__aria-live {
  //TODO remove when react-datepicker fixes useless display
  display: none;
}

// Badges
.current {
  .lgt-badge__label {
    background-color: var(--primary) !important;
    border-color: var(--primary) !important ;
    color: var(--white) !important;
  }
}

.closed,
.open {
  .lgt-badge__label {
    background-color: #d1dade !important;
    border-color: #d1dade !important;
    color: #5e5e5e !important;
  }
}

.settled {
  .lgt-badge__label {
    background-color: var(--success) !important;
    border-color: var(--success) !important;
    color: var(--white) !important;
  }
}

.dormant {
  .lgt-badge__label {
    background-color: #f8ac59 !important;
    border-color: #f8ac59 !important;
    color: var(--white) !important;
  }
}

.lgt-dropdown-list__empty {
  padding-bottom: 20px;
}

.lgt-table-placeholder {
  text-align: center;

  td {
    padding-top: 15px;
  }
}

.lgt-table-thead .lgt-table__cell {
  white-space: normal;
  vertical-align: middle;
}

.lgt-table__cell {
  padding: 10px;
}

.lgt-dropdown-list--container {
  padding-top: 0;
  padding-bottom: 0;
}

.lgt-modal__header {
  position: relative;

  .lgt-modal__close-button {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
  }
}

.lgt-table .lgt-checkbox {
  padding: 12px;
}

// Media queries
@media (max-width: 576px) {
  .form-group {
    grid-auto-flow: row;
  }
}
