.header {
  background-color: var(--white);
  padding: 10px 10px 20px;
  margin: -19px -24px 24px -24px;

  .buttons {
    display: flex;
    justify-content: space-between;
  }
}

.container {
  padding: 15px 20px 20px;
  background-color: var(--white);
  display: grid;
  gap: 25px;

  .selector {
    display: flex;
    justify-content: space-between;
    align-items: self-start;
  }

  .table {
    width: 75%;
  }
}
