.header {
  display: flex;
  background-color: var(--white);
  padding: 10px 10px 20px;
  margin: -19px -24px 24px -24px;
}

.container {
  padding: 15px 20px 20px;
  background-color: var(--white);

  .button-wrapper {
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 20px;
  }
}
