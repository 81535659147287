.header {
  background-color: var(--white);
  padding: 10px 10px 20px;
  margin: -19px -24px 24px -24px;
}

.container {
  padding: 15px 20px;
  background-color: var(--white);
  line-height: 1.5;

  .edit {
    color: #b45400;
  }

  .audit-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .activities {
    display: flex;
    gap: 20px;
  }

  .summary {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
}
