.header {
  display: flex;
  gap: 10px;
  padding-bottom: 20px;
}

.form-group {
  display: grid;
  grid-auto-flow: column;
  gap: 30px;
  margin-bottom: 1rem;

  &-one {
    width: 50%;
  }

  &-three {
    grid-auto-columns: 2fr 1fr 1fr;
  }

  &-four {
    grid-auto-columns: 1fr 1fr 1fr 1fr;
  }

  .input-group {
    margin-bottom: 1rem;
  }
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 576px) {
  .form-group {
    grid-auto-flow: row;
  }
}
