.container {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    "sidebar header"
    "sidebar main"
    "sidebar footer";
  position: relative;
  min-height: 100vh;
  background: #f3f3f4;

  .header {
    grid-area: header;
    position: sticky;
    top: 0;
    z-index: 100;
  }

  .body {
    padding: 20px 25px 40px;
    grid-area: main;
    max-width: 100vw;
  }

  .sidebar {
    grid-area: sidebar;
  }

  .footer {
    grid-area: footer;
    align-self: end;
  }
}
